var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.game
    ? _c("div", { staticClass: "lobby-room-mobile" }, [
        _c(
          "div",
          { staticClass: "lobby-room-mobile__head", style: _vm.headStyle },
          [
            _c("div", { staticClass: "lobby-room-mobile__title" }, [
              _vm._v(" " + _vm._s(_vm.name) + " "),
            ]),
            _c("LobbyRoomHost", {
              staticClass: "lobby-room-mobile__host",
              attrs: { user: _vm.roomHost },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "lobby-room-mobile__body" }, [
          _c("div", { staticClass: "lobby-room-mobile__description" }, [
            _vm._v(" " + _vm._s(_vm.description) + " "),
          ]),
          _c("div", { staticClass: "lobby-room-mobile__details" }, [
            _c("div", [
              _vm._v(" Game Time "),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.startTime) + " "),
            ]),
            _c("div", [
              _vm._v(" Duration "),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.duration) + " "),
            ]),
            _c(
              "div",
              [
                _c("GameTime", {
                  attrs: {
                    game: _vm.game,
                    time: _vm.time,
                    gameEndTime: _vm.gameEndTime,
                    isGameBooked: _vm.isGameBooked,
                    gameStartTime: _vm.gameStartTime,
                    isPlayedByUser: _vm.isVisitedByUser,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "lobby-room-mobile__controls" },
            [
              _vm.isGameSpectable
                ? _c(
                    "RtbButton",
                    {
                      attrs: { outline: "", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.tryToJoin({ audit: true })
                        },
                      },
                    },
                    [_vm._v(" Spectate ")]
                  )
                : _vm._e(),
              _vm.canShowBigButton
                ? [
                    _vm.status === _vm.STATUSES.AVAILABLE
                      ? _c(
                          "RtbButton",
                          {
                            attrs: { disabled: _vm.loading },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.tryToJoin.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" Join Now! ")]
                        )
                      : _vm.status === _vm.STATUSES.LOCKED
                      ? _c(
                          "RtbButton",
                          {
                            attrs: { color: "grey", disabled: "", outline: "" },
                          },
                          [_vm._v(" Locked ")]
                        )
                      : true || _vm.status === _vm.STATUSES.PLAYED
                      ? _c(
                          "RtbButton",
                          {
                            attrs: { color: "grey", disabled: "", outline: "" },
                          },
                          [_vm._v(" Played ")]
                        )
                      : _vm.status === _vm.STATUSES.FULL
                      ? _c(
                          "RtbButton",
                          {
                            attrs: { color: "grey", disabled: "", outline: "" },
                          },
                          [_vm._v(" Full ")]
                        )
                      : _vm._e(),
                  ]
                : [
                    _vm.isEnterable
                      ? _c(
                          "RtbButton",
                          {
                            attrs: { outline: "", disabled: _vm.loading },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onEnterGame.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" Enter ")]
                        )
                      : _vm._e(),
                    _vm.isLeaveable
                      ? _c(
                          "RtbButton",
                          {
                            attrs: { disabled: _vm.loading },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onLeaveGame.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" Leave ")]
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
          _c("div", { staticClass: "lobby-room-mobile__attendees" }, [
            _vm._v(
              " Attendees " +
                _vm._s(_vm.nOfRoomPlayers) +
                " of " +
                _vm._s(_vm.nOfMaxPlayers) +
                " "
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }