var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "lobby-mobile",
      class: { "lobby-mobile--landscape": _vm.isLandscape },
    },
    [
      _c(
        "RtbControlButton",
        {
          staticClass: "lobby-mobile__menu-btn",
          on: {
            click: function ($event) {
              _vm.menuActive = !_vm.menuActive
            },
          },
        },
        [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("menu")])],
        1
      ),
      _c(
        "div",
        { staticClass: "lobby-mobile__top" },
        [
          _c("h1", { staticClass: "lobby-mobile__title" }, [
            _vm._v(_vm._s(_vm.lobbyTitle)),
          ]),
          _vm.selectedGame
            ? _c("LobbyRoomMobile")
            : _c("h2", [_vm._v("Welcome")]),
        ],
        1
      ),
      _c("LobbyRoomsMobile"),
      _c("MobileSideBar", {
        on: {
          onSwapPhone: function ($event) {
            _vm.swapPhone = true
          },
          onChangeAV: function ($event) {
            _vm.aVSettings = true
          },
          openChat: _vm.openChat,
        },
        model: {
          value: _vm.menuActive,
          callback: function ($$v) {
            _vm.menuActive = $$v
          },
          expression: "menuActive",
        },
      }),
      _c("SwapPhone", {
        model: {
          value: _vm.swapPhone,
          callback: function ($$v) {
            _vm.swapPhone = $$v
          },
          expression: "swapPhone",
        },
      }),
      _c("MobileAVSettings", {
        model: {
          value: _vm.aVSettings,
          callback: function ($$v) {
            _vm.aVSettings = $$v
          },
          expression: "aVSettings",
        },
      }),
      _vm.chatActive
        ? _c("MobileChat", {
            on: {
              closeChat: function ($event) {
                _vm.chatActive = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }