
































import { computed, defineComponent, ref } from "@vue/composition-api"

import useClientGames from "@/use/useClientGames"
import useClientGamesSubscription from "@/use/useClientGamesSubscription"
import useStore from "@/use/useStore"
import useLobby from "@/use/useLobby"
import useLobbyRooms from "@/use/useLobbyRooms"

import { RtbControlButton } from "@/components/ui"
import MobileSideBar from "@/components/GroupTeams/Mobile/MobileSideBar.vue"
import SwapPhone from "@/components/GroupTeams/Mobile/SwapPhone.vue"
import MobileAVSettings from "@/components/GroupTeams/Mobile/MobileAVSettings.vue"
import LobbyRoomsMobile from "@/components/Lobby/LobbyRoomsMobile.vue"
import LobbyRoomMobile from "@/components/Lobby/LobbyRoomMobile.vue"
import ORIENTATIONS from "@/components/GroupTeams/Mobile/orientations"

export default defineComponent({
  name: "LobbyMobile",
  components: {
    MobileChat: () => import("@/components/GroupTeams/Mobile/MobileChat.vue"),
    RtbControlButton,
    MobileSideBar,
    SwapPhone,
    MobileAVSettings,
    LobbyRoomsMobile,
    LobbyRoomMobile
  },
  setup() {
    useClientGamesSubscription()

    const chatActive = ref(false)
    const aVSettings = ref(false)
    const swapPhone = ref(false)
    const menuActive = ref(false)
    const { store } = useStore()
    const { games } = useClientGames()

    const isHost = computed(() => store.getters["auth/isHost"])
    const isMobile = computed(() => store.getters["Mobile/mobile"])
    const isLandscape = computed(
      () => store.getters["Mobile/orientation"] === ORIENTATIONS.LANDSCAPE
    )
    const client = computed(() => store.getters["auth/client"])
    const lobbyTitle = computed(
      () => client.value?.lobbyName || client.value?.name
    )
    const {
      isVideo,
      isStandard,
      isImage,
      selectedUserID,
      isGameVideoVisible,
      noAnnouncement
    } = useLobby()
    const {
      sortedGames,
      selectedGame,
      isRoomVisitedByUser,
      isRoomUnlockedByUser
    } = useLobbyRooms()

    function openChat() {
      chatActive.value = true
      menuActive.value = false
    }
    return {
      lobbyTitle,
      isMobile,
      sortedGames,
      client,
      isHost,
      games,
      isImage,
      isVideo,
      isStandard,
      selectedGame,
      selectedUserID,
      isGameVideoVisible,
      noAnnouncement,
      isRoomVisitedByUser,
      isRoomUnlockedByUser,
      swapPhone,
      menuActive,
      isLandscape,
      openChat,
      chatActive,
      aVSettings
    }
  }
})
