










import { defineComponent } from "@vue/composition-api"

import useLobby from "@/use/useLobby"
import useLobbyRooms from "@/use/useLobbyRooms"

import GameSearch from "@/components/Lobby/GameSearch.vue"

export default defineComponent({
  name: "LobbyRoomsMobile",
  components: { GameSearch },
  setup() {
    const { sortedGames, isRoomVisitedByUser } = useLobbyRooms()

    const { actualSelectedGameID } = useLobby()

    return { sortedGames, isRoomVisitedByUser, actualSelectedGameID }
  }
})
