





































































































import { defineComponent } from "@vue/composition-api"

import useLobbyRoom from "@/use/useLobbyRoom"

import { RtbButton } from "@/components/ui"
import GameTime from "@/components/Lobby/GameTime.vue"
import LobbyRoomHost from "@/components/Lobby/LobbyRoomHost.vue"

export default defineComponent({
  name: "LobbyRoomMobile",
  components: { GameTime, RtbButton, LobbyRoomHost },
  setup() {
    const {
      game,
      name,
      time,
      description,
      gameStartTime,
      gameEndTime,
      isVisitedByUser,
      startTime,
      isGameBooked,
      isGameSpectable,
      canShowBigButton,
      status,
      STATUSES,
      isEnterable,
      isLeaveable,
      tryToJoin,
      onEnterGame,
      onLeaveGame,
      duration,
      loading,
      nOfRoomPlayers,
      nOfMaxPlayers,
      headStyle,
      roomHost
    } = useLobbyRoom()

    return {
      game,
      name,
      time,
      description,
      gameStartTime,
      isVisitedByUser,
      isGameSpectable,
      canShowBigButton,
      status,
      STATUSES,
      isEnterable,
      isLeaveable,
      isGameBooked,
      gameEndTime,
      startTime,
      tryToJoin,
      onEnterGame,
      onLeaveGame,
      nOfRoomPlayers,
      nOfMaxPlayers,
      duration,
      roomHost,
      headStyle,
      loading
    }
  }
})
