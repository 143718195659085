import { computed, onMounted, onBeforeUnmount } from "@vue/composition-api"

import ActionTypes from "@/store/action-types"

import useStore from "./useStore"

export default function useClientGamesSubscription() {
  const { store } = useStore()

  onMounted(() => {
    store.dispatch(ActionTypes.SUBSCRIBE_TO_CLIENT_GAMES)
  })

  onBeforeUnmount(() => {
    store.dispatch(ActionTypes.UNSUBSCRIBE_FROM_CLIENT_GAMES)
  })
}
